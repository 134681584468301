<!-- Add Plan Modal -->
<template>
  <b-modal
    id="modalPaymentMethod"
    lazy
    no-fade
    size="md"
    :title="data.modal.title"
    @show="handleOpen"
  >
    <b-form validate>
      <b-form-row>
        <b-form-group
          id="fg-payment-holder-name"
          :label="data.type === 'credit_card' ? $t('payments.paymentMethods.labelPaymentCardName') : $t('payments.paymentMethods.labelPaymentDriverName')"
          label-for="payment-holder-name"
          class="col-md-8 col-sm-12"
        >
          <b-form-input id="payment-holder-name" v-model.trim="data.cc_holder_name" :disabled="block_ui"
                        type="text"
          />
        </b-form-group>
        <b-form-group
          id="fg-payment-holder-cpf"
          :label="data.type === 'credit_card' ? $t('payments.paymentMethods.labelPaymentCardDoc') : $t('payments.paymentMethods.labelPaymentDriverDoc')"
          label-for="payment-holder-cpf"
          class="col-md-4 col-sm-12"
        >
          <the-mask
            v-if="$regions.isBrazil()"
            id="payment-holder-cpf"
            v-model.trim="data.cc_holder_cpf"
            type="text"
            :disabled="block_ui"
            class="form-control"
            mask="###.###.###-##"
          />
          <b-form-input v-else id="payment-holder-cpf" v-model.trim="data.cc_holder_cpf"
                        :disabled="block_ui" type="text" class="form-control"
          />
        </b-form-group>
      </b-form-row>
      <b-form-row v-if="data.type === 'credit_card'">
        <b-form-group id="fg-payment-number" :label="$t('payments.paymentMethods.labelPaymetNumber')" label-for="payment-number"
                      class="col-md-5 col-sm-12"
        >
          <the-mask id="payment-number" v-model.trim="data.cc_number" type="text"
                    :disabled="block_ui" class="form-control" mask="#### #### #### ####"
          />
        </b-form-group>
        <b-form-group
          id="fg-payment-expiry-month"
          :label="$t('payments.paymentMethods.labelPaymentExpiryMonth')"
          label-for="payment-expiry-month"
          class="col-md-2 col-sm-12"
        >
          <b-select
            id="payment-expiry-month"
            v-model.trim="data.cc_expiry_month"
            type="text"
            :disabled="block_ui"
            :options="form_expiry_month"
            class="form-control"
          />
        </b-form-group>
        <b-form-group id="fg-payment-expiry-year" label="&nbsp;" label-for="payment-expiry-year"
                      class="col-md-3 col-sm-12"
        >
          <b-select
            id="payment-expiry-year"
            v-model.trim="data.cc_expiry_year"
            type="text"
            :disabled="block_ui"
            :options="form_expiry_year"
            class="form-control"
          />
        </b-form-group>
        <b-form-group id="fg-payment-cvc" label="CVC" label-for="payment-cvc"
                      class="col-md-2 col-sm-12"
        >
          <the-mask id="payment-cvc" v-model.trim="data.cc_cvc" type="text"
                    :disabled="block_ui" class="form-control" :mask="['###']"
          />
        </b-form-group>
      </b-form-row>
      <div v-if="requestDriverInfo(data.type)">
        <b-form-row>
          <b-form-group label="Rua" class="col-md-9 col-sm-12">
            <b-form-input v-model.trim="data.driver.address_street_name" :disabled="block_ui" type="text" />
          </b-form-group>
          <b-form-group label="Número" class="col-md-3 col-sm-12">
            <b-form-input v-model.trim="data.driver.address_street_number" :disabled="block_ui" type="text" />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label="Bairro" class="col-md-4 col-sm-12">
            <b-form-input v-model.trim="data.driver.address_neighborhood" :disabled="block_ui" type="text" />
          </b-form-group>
          <b-form-group label="Cidade" class="col-md-6 col-sm-12">
            <b-form-input v-model.trim="data.driver.address_city" :disabled="block_ui" type="text" />
          </b-form-group>
          <b-form-group label="Estado" class="col-md-2 col-sm-12">
            <b-form-input v-model.trim="data.driver.address_state" :disabled="block_ui" type="text" />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label="Complemento" class="col-md-8 col-sm-12">
            <b-form-input v-model.trim="data.driver.address_street_details" :disabled="block_ui" type="text" />
          </b-form-group>
          <b-form-group label="CEP" class="col-md-4 col-sm-12">
            <b-form-input v-model.trim="data.driver.address_postal_code" :disabled="block_ui" type="text" />
          </b-form-group>
        </b-form-row>
      </div>
    </b-form>
    <template slot="modal-footer">
      <b-button size="md" @click="handleClose()">
        {{ data.id ? $t('payments.buttons.close') : $t('payments.buttons.cancel') }}
      </b-button>
      <b-button v-if="!data.id" size="md" variant="success"
                @click.prevent="handleSubmit()"
      >
        {{ data.modal.save_button }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import PAYMENTMETHOD_CREATE from '@graphql/payment/mutations/create.gql';
import { expiryMonth, expiryYear } from '@utils/payment-method';

export default {
  name: 'ModalPaymentMethod',
  props: {
    modalData: {
      type: Object,
      default: () => ({
        id: null,
        driver: {},
        cc_holder_name: '',
        cc_holder_cpf: '',
        cc_cvc: '',
        cc_number: '',
        cc_expiration_date: '',
        status: ''
      })
    },
  },
  data() {
    return {
      data: {
        id: null,
        cc_holder_name: '',
        cc_holder_cpf: '',
        cc_cvc: '',
        cc_number: '',
        cc_expiry_month: '',
        cc_expiry_year: '',
        status: '',
        type: null,
        driver: {
          name: null,
          cpf: null,
        },
        cta_save: '',
        title_success: '',
        modal: {
          title: '',
          save_button: '',
          save_success_title: '',
        },
      },
      pre_block_ui: false,
      block_ui: false,
      form_expiry_month: expiryMonth,
      form_expiry_year: expiryYear,
    };
  },
  methods: {
    setCardToken() {
      const key = process.env.ADYEN_CSE_KEY;
      const options = {
        enableValidations: false,
      };
      // eslint-disable-next-line
      const cseInstance = adyen.createEncryption(key, options);
      const cardData = {
        number: this.data.cc_number,
        cvc: this.data.cc_cvc,
        holderName: this.data.cc_holder_name,
        expiryMonth: this.data.cc_expiry_month,
        expiryYear: this.data.cc_expiry_year,
        generationtime: this.$moment.utc().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      };
      return cseInstance.encrypt(cardData);
    },
    requestDriverInfo(_type) {
      ['boleto', 'pix', 'oxxo'].includes(_type);
    },
    setComponentStateIfIdExist() {
      this.block_ui = true;
      this.pre_block_ui = true;
      this.data.id = this.modalData.id;
      this.data.driver = this.modalData.driver;
      this.data.type = this.modalData.type;
      this.data.cc_holder_name = this.modalData.cc_holder_name || this.modalData.driver.name;
      this.data.cc_holder_cpf = this.modalData.cc_holder_cpf || this.modalData.driver.cpf;
      this.data.cc_cvc = '000';
      this.data.cc_brand = this.modalData.cc_brand;
      this.data.cc_number = this.modalData.cc_number;
      this.data.cc_expiry_month = this.$moment(this.modalData.cc_expiration_date).format('MM');
      this.data.cc_expiry_year = this.$moment(this.modalData.cc_expiration_date).format('YYYY');
      this.data.status = this.modalData.status;
      this.data.modal.title =
        this.data.type === 'credit_card' ? this.$t('payments.paymentMethods.modalTitleCreditCard') : this.$t('payments.paymentMethods.modalTitleBillet');
      this.data.modal.save_button = this.$t('payments.paymentMethods.modalSaveButton');
      this.data.modal.save_success_title = this.$t('payments.paymentMethods.modalSaveSuccess');
    },
    setComponentStateIfIdNotExist() {
      this.block_ui = false;
      this.pre_block_ui = false;
      this.data.id = null;
      this.data.type = 'credit_card';
      this.data.cc_holder_name = null;
      this.data.cc_holder_cpf = null;
      this.data.cc_cvc = '';
      this.data.cc_brand = '';
      this.data.cc_number = '';
      this.data.cc_expiry_month = '';
      this.data.cc_expiry_year = '';
      this.data.status = 'ACTIVE';
      this.data.modal.title = this.$t('payments.paymentMethods.modalNewCardTitle');
      this.data.modal.save_button = this.$t('payments.paymentMethods.modalNewCardSaveBtn');
      this.data.modal.save_success_title = this.$t('payments.paymentMethods.modalNewCardSuccess');
    },
    handleOpen() {
      if (this.modalData.id) {
        this.setComponentStateIfIdExist();
      } else {
        this.setComponentStateIfIdNotExist();
      }
    },
    createPaymentMethodCreditCard(card_token) {
      return this.$apollo.mutate({
        mutation: PAYMENTMETHOD_CREATE,
        variables: {
          driver: this.$route.params.id,
          cc_holder_name: this.data.cc_holder_name,
          cc_holder_cpf: this.data.cc_holder_cpf,
          token: card_token,
          status: 'ACTIVE',
          type: this.data.type,
        },
      });
    },
    successToCreateCreditCard() {
      this.$root.$emit('bv::hide::modal', 'modalPaymentMethod');
      this.$swal({
        type: 'success',
        title: this.data.modal.title_success,
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        this.$emit('refresh')
      });
    },
    errorToCreateCreditCard(original_data) {
      this.$swal({
        type: 'error',
        title: this.$t('payments.paymentMethods.errorToCreateCreditCard'),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        this.$emit('refresh')
      });
      this.block_ui = false;
      this.modal = original_data;
    },
    errorToEncryptCreditCard() {
      this.$swal({
        type: 'error',
        title: this.$t('payments.paymentMethods.errorToEncryptCreditCard'),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        this.$emit('refresh')
      });
    },
    async doCreatePaymentMethod() {
      try {
        const original_data = this.modal;
        const card_token = this.setCardToken();

        if (card_token !== false) {
          this.createPaymentMethodCreditCard(card_token)
            .then(() => {
              this.successToCreateCreditCard();
            })
            .catch(() => {
              this.errorToCreateCreditCard(original_data);
            });
        } else {
          this.errorToEncryptCreditCard();
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async handleSubmit() {
      if (!this.data.id) this.doCreatePaymentMethod();
    },
    handleClose() {
      this.$root.$emit('bv::hide::modal', 'modalPaymentMethod');
    },
  },
};
</script>
