<template>
  <div>
    <div v-if="!$apollo.queries.paymentMethods.loading && profile.id" class="animated fadeIn">
      <b-card body-class="p-0">
        <b-card-header>
          <b-row>
            <b-col cols="8">
              <h4 class="mt-2">
                {{ $t('payments.titles.paymentMethods') }}
              </h4>
            </b-col>
            <b-col cols="4" class="text-right mt-1">
              <b-button
                variant="success"
                class="mr-2"
                @click="goTo(null)"
              >
                {{ $t('payments.buttons.newCreditCard') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            responsive
            striped
            hover
            :items="paymentMethods.items"
            :fields="fields"
            @row-clicked="goTo"
          >
            <template v-slot:cell(type)="data">
              <img
                v-if="data.item.type === 'pix'"
                style="width: 23px"
                class="fa mr-1"
                src="@assets/logos/pix.png"
              >
              <img
                v-if="data.item.type === 'oxxo'"
                style="width: 23px"
                class="fa mr-1"
                src="@assets/logos/oxxo.svg"
              >
              <i
                v-else
                :class="setTypeIcon(data.item.type) + ' mr-1'"
                style="width:18px;text-align:center"
              />
              <span v-if="data.item.type === 'multi_payment_method'">
                <strong>{{ $t('payments.enumMethod.multiPaymentMethod') }}</strong>
                <br>
                <div
                  class="text-muted mt-1"
                  style="padding-left:25px"
                >
                  <ApolloQuery
                    :query="gql => gql`
                      query getMultiPaymentMethod($id: ID!) {
                        getMultiPaymentMethod(id: $id) {
                          multi_payment_method_id
                          payment_methods {
                            payment_method
                            payment_method_id
                            amount
                            installments
                            invoice_id
                            gateway
                          }
                        }
                      }
                    `"
                    :variables="{ id: data.item.token }"
                  >
                    <template v-slot="{ result: { loading, error, data } }">
                      <!-- Loading -->
                      <div v-if="loading" class="loading apollo">Carregando...</div>

                      <!-- Error -->
                      <div v-else-if="error" class="error apollo">Algo de errado ocorreu...</div>

                      <!-- Result -->
                      <div v-else-if="data" class="result apollo">
                        <span v-bind:key="card.id" v-for="card in getMultiPaymentMethodCards(data)" >
                          <strong>{{ $t('payments.enumMethod.creditCard') }}</strong>
                          <br>
                          <div
                            class="text-muted mt-1"
                            style="padding-left:25px"
                          >
                          {{ card.cc_holder_name }} <br> (CPF: {{ card.cc_holder_cpf }}) <br>
                          {{ setCreditCardName(card.cc_brand) }} (**** {{ card.cc_number }})</div>
                        </span>
                      </div>

                      <div v-else class="no-result apollo">Sem resultados</div>
                    </template>
                  </ApolloQuery>
              </div>
              </span>
              <span v-if="data.item.type === 'credit_card'">
                <strong>{{ $t('payments.enumMethod.creditCard') }}</strong>
                <br>
                <div
                  class="text-muted mt-1"
                  style="padding-left:25px"
                >{{ setCreditCardName(data.item.cc_brand) }} (**** {{ data.item.cc_number }})</div>
              </span>
              <span v-else>
                <strong
                  v-if="data.item.type === 'boleto'"
                  v-text="$t('payments.enumMethod.billet')"
                />
                <strong
                  v-if="data.item.type === 'pix'"
                  v-text="$t('payments.enumMethod.pix')"
                />
                <strong
                  v-if="data.item.type === 'oxxo'"
                  v-text="$t('payments.enumMethod.oxxo')"
                />
              </span>
            </template>
            <template v-slot:cell(description)="data">
              <span v-if="data.item.type === 'credit_card'">
                {{ data.item.cc_holder_name }}
                <br>
                <div
                  class="text-muted mt-1"
                >({{ $t('payments.texts.document') + ': ' + data.item.cc_holder_cpf }})</div>
              </span>
              <span v-if="showDriverInfo(data.item.type)">
                {{ data.item.driver.name }}
                <br>
                <div
                  class="text-muted mt-1"
                >({{ $t('payments.texts.document') + ': ' + data.item.driver.cpf }})</div>
              </span>
            </template>
            <template v-slot:cell(expired_at)="data">
              <span
                v-if="data.item.type === 'credit_card'"
              >{{ data.item.cc_expired_at | moment('DD/YYYY') }}</span>
              <span v-else>Nunca</span>
            </template>
            <template v-slot:cell(status)="data">
              <b-badge :variant="setStatusFormat(data.item.status)">
                {{ data.item.status }}
              </b-badge>
            </template>
            <template v-slot:cell(recurrency)="data">
              <div class="pl-3">
                <i
                  v-if="usedRecurrence(data)"
                  class="fa fa-check fa-2x text-success pr-1"
                  :title="$t('payments.labels.usedRecurrency')"
                />
              </div>
            </template>
            <template v-slot:cell(actions)="data">
              <div v-if="notInUse(data)">
                <b-button
                  v-b-tooltip.hover
                  :title="$t('payments.labels.removePaymentMethod')"
                  variant="danger"
                  size="sm"
                  class="mr-2"

                  @click="inactivePaymentMethod(data.item.id)"
                >
                  <i class="fa fa-trash" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body
          v-if="paymentMethods.errorType || paymentMethods.items.length === 0"
          class="p-0"
        >
          <content-controll :service="paymentMethods" />
        </b-card-body>
      </b-card>
      <modal-payment-method
        :modal_data="modal_data"
        @refresh="refreshPaymentsMethods"
      />
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </div>
</template>

<script>
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import ModalPaymentMethod from '@components/modals/payment-method';
import { formatStatus } from '@utils/payment-method';
import { makeMutation } from "@graphql/middleware";
import PAYMENTMETHOD_GET from '@graphql/payment/queries/get.gql';
import INACTIVE_PAYMENT_METHOD from '@graphql/payment/mutations/inactivePaymentMethod.gql';
import { mapGetters } from 'vuex';

export default {
  name: 'DriverWalletPaymentMethods',
  page: {
    title: 'Métodos de Pagamento - Motoristas',
  },
  components: {
    ContentControll,
    ContentLoading,
    ModalPaymentMethod,
  },
  data() {
    return {
      fields: [
        {
          key: 'type',
          label: this.$t('payments.paymentMethods.tableFieldType'),
        },
        {
          key: 'description',
          label: this.$t('payments.paymentMethods.tableFieldDescription'),
        },
        {
          key: 'expired_at',
          label: this.$t('payments.paymentMethods.tableFieldExpiredAt'),
        },
        {
          key: 'recurrency',
          label: this.$t('payments.paymentMethods.tableFieldRecurrency'),
        },
        {
          key: 'status',
          label: 'status',
          class: 'text-right',
        },
        {
          key: 'actions',
          label: 'Ações',
          class: 'text-right',
        },
      ],
      modal_data: {
        id: null,
        type: null,
        driver: {
          name: null,
          cpf: null,
        },
        cc_holder_name: null,
        cc_holder_cpf: null,
        cc_cvc: null,
        cc_number: null,
        cc_expiration_date: null,
        status: null,
      },
      loadingMultiPaymentMethods: false,
      multi_payment_method_aggregators: [] // will put the credit card inside of it
    };
  },
  apollo: {
    paymentMethods: {
      query: PAYMENTMETHOD_GET,
      variables() {
        return {
          driver: this.$route.params.id,
        };
      },
    },
  },
  computed: {
    ...mapGetters('driver', {
      profile: 'header'
    }),
  },
  methods: {
    getInvoiceFromMultiPaymentMethod(data) {
      const {invoice_id} = data.getMultiPaymentMethod.payment_methods.find(item => item.invoice_id)
      if (invoice_id) {
        return `${process.env.KOVI_FATURA_URL}${invoice_id}`
      }
      return null
    },
    getMultiPaymentMethodCards(data) {
      const card_ids = data.getMultiPaymentMethod.payment_methods.map(item => item.payment_method_id)
      return this.paymentMethods.items.filter(item => card_ids.includes(item.token))
    },
    goTo(_item = null) {
      if (_item) return;

      this.modal_data.id = null;
      this.$root.$emit('bv::show::modal', 'modalPaymentMethod');
    },
    setTypeIcon(_type) {
      switch(_type) {
        case 'credit_card':
          return 'fa fa-credit-card-alt'
        case 'multi_payment_method':
          return 'fa-solid fa-money-check-dollar'
        case 'boleto':
          return 'fa fa-barcode'
        default:
          return ''
      }
    },
    showDriverInfo(_type) {
      ['boleto', 'pix', 'oxxo'].includes(_type);
    },
    setCreditCardName(_name) {
      return _name.charAt(0).toUpperCase() + _name.slice(1);
    },
    setStatusFormat(_value) {
      return formatStatus(_value);
    },
    setarComoDefault(data) {
      console.log(data);
    },
    notInUse(data) {
      return data.item.type === 'credit_card' &&
        !data.item.used &&
        this.profile.booking?.transaction_method?.id !== data.item.id
    },
    usedRecurrence (data) {
      return this.profile.booking?.transaction_method?.id === data.item.id
    },
    inactivePaymentMethod(id) {
      console.log('id-payment', id)
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('payments.creditRequest.modalConfirmationTitle'),
        text: this.$t('payments.labels.inactivePaymentMethod'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('payments.buttons.confirm'),
        cancelButtonText: this.$t('payments.buttons.cancel'),
        showLoaderOnConfirm: true,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {

          makeMutation(
            INACTIVE_PAYMENT_METHOD,
            { id }
          )
          .then(() => {
              this.showSuccessModal();
              this.refreshPaymentsMethods();
            })
            .catch(() => {
              this.showErrorModal();
            });
        }
      });
    },
    showSuccessModal() {
      this.$swal({
        type: 'success',
        title: 'Ação realizada com Sucesso!',
        showConfirmButton: false,
        timer: 2500,
      })
    },
    showErrorModal() {
      this.$swal({
        type: 'error',
        title: 'Ocorreu um erro!',
        text: 'Verifique as informações novamente',
        showConfirmButton: false,
        timer: 2500,
      });
    },
    refreshPaymentsMethods() {
      this.$apollo.queries.paymentMethods.refresh();
    }
  },
};
</script>
